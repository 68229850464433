// AffiliateSelfSearchSalesTransactions.js
import React from 'react';

const formatDateOutput = (date) => {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).format(new Date(date));
};

const getAffiliateNameFromUrlString = (url) => {
    const delimiter = url.includes('-') ? '-' : '/';

    if (url.includes(delimiter)) {
        const parts = url.split(delimiter);
        return parts[parts.length - 1];
    }
    
    return url;
};

const AffiliateSelfSearchRevenueCatSalesTransactions = ({
    tableData,
    companyAffiliatePaymentPercentage
}) => (
    <div>
        <h2 className="mt-4 mb-4 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">
            Transactions
        </h2>

        {tableData.length === 0 ? (
            <p className="pt-8">No earnings data available yet. Please check back later.</p>
        ) : (
            <div>
                <table className="dataTable">
                    <thead>
                        <tr>
                            <th>Customer UUID</th>
                            <th>Transaction ID</th>
                            <th>Transaction Type</th>
                            <th>Product ID</th>
                            <th>Original Purchase Date</th>
                            <th>Expiration Date</th>
                            <th>Transaciton Total Amount ($)</th>
                            <th>Amount You Earned</th>
                            <th>Status of Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((transaction, index) => (
                            <tr key={`${index}`}>
                                <td>{getAffiliateNameFromUrlString(transaction.app_user_id)}</td>
                                <td>{transaction.transaction_id}</td>
                                <td>{transaction.type}</td>
                                <td>{transaction.product_id}</td>
                                <td>{formatDateOutput(transaction.purchased_at_ms)}</td>
                                <td>{formatDateOutput(transaction.expiration_at_ms)}</td>
                                <td>{transaction.price}</td>
                                <td>
                                    {(transaction.price * companyAffiliatePaymentPercentage) / 100}
                                </td>
                                <td
                                    className={` ${
                                        transaction.affiliateHasBeenPaid ? 'text-green-600' : 'text-red-600'
                                    }`}
                                >
                                    {transaction.affiliateHasBeenPaid ? "Paid" : "Unpaid"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )}
    </div>
);

export default AffiliateSelfSearchRevenueCatSalesTransactions;
