import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Container } from '../../components/Container';
import { ApiContext } from '../../contexts/ApiContext';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js'
import { Button } from '../../components/Button.js';
import UseCustomClaims from '../../components/UseCustomClaims.js';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import AffiliateSelfSearchSalesTransactions from './AffiliateSelfSearchSalesTransactions.js';
import AffiliateSelfSearchRevenueCatSalesTransactions from './AffiliateSelfSearchRevenueCatSalesTransactions.js';
import AffiliateSelfSearchEvents from './AffiliateSelfSearchEvents.js';

const formatDateOutput = (date) => {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).format(new Date(date));
};

const getAffiliateNameFromUrlString = (url) => {
    const delimiter = url.includes('-') ? '-' : '/';

    if (url.includes(delimiter)) {
        const parts = url.split(delimiter);
        return parts[parts.length - 1];
    }

    return url;
};

const AffiliateSelfSearch = () => {
    const [eventsTableData, setEventsTableData] = useState([]);
    const claims = UseCustomClaims();
    const [tableData, setTableData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchExecuted, setSearchExecuted] = useState(false);
    const [error, setError] = useState(null); // State for handling errors
    const {affiliateName, apiUrl, deepLink, selectedVerificationMethod, companyAffiliatePaymentPercentage} = useContext(ApiContext);
    const user = FetchUserAndFirebaseDocsOnAuth();
    const auth = getAuth();
    const [view, setView] = useState('Transactions'); 
    const [transactionsAffiliateHasBeenPaidFor, setTransactionsAffiliateHasBeenPaidFor] = useState([]);

    const searchAnAffiliatesEvents = async () => {
        setSearchExecuted(true);
        setEventsTableData([]); // Reset table data
        setError(null);
    
        if (deepLink === '') {
            setError("Affiliate name is required to fetch data.");
            return;
        }

        try {
            let url = `${apiUrl}/userEvents/${user.uid}?storedDeepLink=${encodeURIComponent(deepLink)}`;

            // Add query parameters if startDate and endDate are provided
            const queryParams = [];
            if (startDate) queryParams.push(`startDate=${encodeURIComponent(startDate)}`);
            if (endDate) queryParams.push(`endDate=${encodeURIComponent(endDate)}`);

            if (queryParams.length > 0) {
                url += `?${queryParams.join('&')}`;
            }

            // Step 2: Fetch events using storedDeepLink
            const response = await fetch(url);
            
            if (!response.ok) {
                throw new Error("Failed to fetch events data.");
            }
    
            const eventsData = await response.json();
            setEventsTableData(eventsData);
        } catch (error) {
            console.error("Error fetching events:", error);
            setError("Failed to fetch events. Ensure affiliate details and dates are correct.");
        }
    };

    const searchAnAffiliate = useCallback(() => {
        setSearchExecuted(true);
        setError(null); // Clear previous errors
        setTableData([]);

        if (startDate && !endDate) {
            // If start date set and end date not set, set end date to today
            // format must be yyyy-mm-dd
            setEndDate(new Date().toISOString().slice(0, 10));
        } else if (!startDate && endDate) {
            // If end date set and start date not set, set start date to 2021-01-01
            setStartDate('2024-01-01');
        }

        if (affiliateName === '') {
            setError("Affiliate name is required to fetch data.");
            return;
        }

        if ((startDate === '' || !startDate) && endDate === '') {
            if (selectedVerificationMethod === 'RevenueCat') {
                try {
                    fetch(`${apiUrl}/get-revenuecat-transactions-from-firebase?companyId=${claims.claims.companyId}&affiliateEmail=${user.email}`)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`Error fetching transactions: ${response.status}`);
                            }
                            return response.json();
                        }).then(response => {
                            if (!response) {
                                throw new Error("Transactions data is undefined. Please check your settings.");
                            }

                            if (response.success) {
                                setTableData(response.transactions);
                            }
                        })
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            } else {
                fetch(`${apiUrl}/affiliateSearchSelf/${claims.claims.companyId}/${affiliateName}`)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`Error fetching data: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        setTableData(data.purchase);
                        setTransactionsAffiliateHasBeenPaidFor(data.transactionsAffiliateHasBeenPaidFor || []);
                    })
                    .catch(error => {
                        console.error("Error fetching data:", error);
                        setError("Failed to fetch data. If this error persists, please contact support.");
                    });
            }
        } else {
            // returnCustomerPurchasesBetweenDates -- this has a different endpoint!
            // fetch(`${apiUrl}/affiliateSearchSelfWithDates/${claims.claims.companyId}/${affiliateName}/${startDate || '2021-01-01'}/${endDate || new Date().toISOString().slice(0, 10)}`)
            //     .then(response => {
            //         if (!response.ok) {
            //             throw new Error(`Error fetching data: ${response.status}`);
            //         }
            //         return response.json();
            //     })
            //     .then(data => {
            //         setTableData(data.purchase);
            //         setTransactionsAffiliateHasBeenPaidFor(data.transactionsAffiliateHasBeenPaidFor || []);
            //     })
            //     .catch(error => {
            //         console.error("Error fetching data:", error);
            //         setError("Failed to fetch data. If this error persists, please contact support.");
            //     });
        }
    }, [claims.claims, affiliateName, startDate, endDate, apiUrl]);

    useEffect(() => {
        if (view === 'Events') {
            searchAnAffiliatesEvents();
        }
    }, [view]);

    useEffect(() => {
        if (deepLink !== '') {
            searchAnAffiliate();
        }
    }, [deepLink]);

    // Checking if the user is an affiliate and if they are, checking if their company is paid up to date
    const [isAffiliate, setIsAffiliate] = useState(null);
    const {subscriptionStatus, setSubscriptionStatus } = useContext(ApiContext);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(()=>{
        if (auth.currentUser) {

            fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/check-affiliate`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ affiliateEmail: auth?.currentUser?.email })
            }).then((res) => res.json()).then(data => {
                setLoading(true);

                fetch(`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/getInitialPayment`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email: data.stripePurchaserEmail })
                })
                .then((res) => res.json()).then(data => {
                    if (data?.status === 'Email not found') {
                        setSubscriptionStatus(false);
                        localStorage.setItem('subscriptionStatus', false);
                        setLoading(false);
                        navigate('/contact-company');
                    } else {
                        setLoading(false)
                    }
                })
                .catch(error => {
                    console.error("Error checking affiliate subscription status:", error);
                    setError("Failed to verify subscription status. Please try again later.");
                })
                .finally(() => {
                    setLoading(false);
                });
            })
        }
    }, [user?.email])


    return (
        <div>
            <Container>
                <div>
                    <div className="pb-8 mx-auto" style={{ minHeight: '65vh' }}>
                        <div className="pb-2">
                            <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                                Sales and Earnings
                            </h1>
                            <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                                Find all sales you have made as an affiliate and your expected earnings!
                            </p>
                        </div>
                        {error && (
                            <div className="mb-4 p-4 text-red-700 bg-red-100 border border-red-400 rounded">
                                {error}
                            </div>
                        )}

                        <div className="mb-8 flex space-x-4">
                            <button
                                onClick={() => setView('Transactions')}
                                className={`px-4 py-2 ${view === 'Transactions' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded`}
                            >
                                Transactions
                            </button>
                            <button
                                onClick={() => setView('Events')}
                                className={`px-4 py-2 ${view === 'Events' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded`}
                            >
                                Events
                            </button>
                        </div>

                        {searchExecuted && view === 'Transactions' && (
                            selectedVerificationMethod === 'RevenueCat' ? (
                                <AffiliateSelfSearchRevenueCatSalesTransactions
                                    tableData={tableData}
                                    companyAffiliatePaymentPercentage={companyAffiliatePaymentPercentage}
                                />
                            ) : (
                                <AffiliateSelfSearchSalesTransactions
                                    tableData={tableData}
                                    transactionsAffiliateHasBeenPaidFor={transactionsAffiliateHasBeenPaidFor}
                                    onSearch={searchAnAffiliate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                />
                            )
                        )}

                        {searchExecuted && view === 'Events' && (
                            eventsTableData.length > 0 ? (
                                <AffiliateSelfSearchEvents
                                    tableData={eventsTableData}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                />
                            ) : (
                                <h2 className="mt-6 font-display text-l tracking-tight text-slate-900 sm:text-xl">No events have been found.</h2>
                            )
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default AffiliateSelfSearch;
