import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { Button } from '../../components/Button';

const AffiliateDetails = ({
  affiliateEmail,
  affiliateName,
  affiliateShortCode,
  deepLinkUrl,
  handleChange,
  handleCreate,
  localCompanyOfferCouponCodes,
  selectedCode,
  handleSubmit,
}) => (
  <div>
    <h2 className="mt-12 mb-4 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">
      Affiliate's Details
    </h2>
    <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-6">
      <div>
        <label htmlFor="affiliateEmail" className="mb-3 block text-sm font-medium text-gray-700">
          Email:
        </label>
        <input
          type="text"
          id="affiliateEmail"
          value={affiliateEmail}
          readOnly
          className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="affiliateName" className="mb-3 block text-sm font-medium text-gray-700">
          Name:
        </label>
        <input
          type="text"
          id="affiliateName"
          value={affiliateName}
          readOnly
          className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="affiliateShortCode" className="mb-3 block text-sm font-medium text-gray-700">
          Affiliate Short Code:
        </label>
        <input
          type="text"
          id="affiliateShortCode"
          value={affiliateShortCode}
          readOnly
          className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
        />
      </div>
      <div>
        <label htmlFor="deepLinkUrl" className="mb-3 block text-sm font-medium text-gray-700">
          Deep Link:
        </label>
        <input
          type="text"
          id="deepLinkUrl"
          value={deepLinkUrl}
          readOnly
          className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
        />
      </div>
      <div>
        <label
          htmlFor="localAffiliateOfferCode"
          className="mb-3 block text-sm font-medium text-gray-700"
        >
          Offer Code (automatically applied to any user that signs up with the affiliate's link):
        </label>
        <CreatableSelect
          id="localCompanyOfferCouponCodes"
          isClearable
          value={selectedCode}
          onChange={handleChange}
          onCreateOption={handleCreate}
          options={localCompanyOfferCouponCodes}
          placeholder="Select or add a new offer code"
          classNamePrefix="react-select"
        />
      </div>
    </div>
    <Button onClick={handleSubmit} className="mx-auto mt-6 pt-2">Save Changes</Button>
  </div>
);
export default AffiliateDetails;
