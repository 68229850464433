import React, { useState, useEffect } from 'react';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const AffiliateRevenueCatSalesTransactions = ({
    tableData,
    setTableData,
    companyAffiliatePaymentPercentage,
    affiliateEmail,
    user,
    totalAffiliateHasBeenPaid,
    setTotalAffiliateHasBeenPaid,
    totalAffiliateHasNotBeenPaid,
    setTotalAffiliateHasNotBeenPaid,
}) => {
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [initalised, setInitialised] = useState(false);
    const [paymentFilter, setPaymentFilter] = useState('unpaid'); // 'all', 'paid', 'unpaid'

    useEffect(() => {
        if (initalised) {
            updateFirebaseAffiliateTransactionsAffiliateHasBeenPaidFor({
                affiliateEmail,
                totalAffiliateHasBeenPaid,
                totalAffiliateHasNotBeenPaid
            });
        } else {
            setInitialised(true);
        }
    }, [affiliateEmail, totalAffiliateHasBeenPaid, totalAffiliateHasNotBeenPaid, initalised]);    

    const updateFirebaseAffiliateTransactionsAffiliateHasBeenPaidFor = async ( affiliate ) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);

        const affiliateRef = doc(collection(userDocRef, 'Affiliates'), affiliate.affiliateEmail);

        try {
            await updateDoc(affiliateRef, {
                totalAffiliateHasBeenPaid: affiliate.totalAffiliateHasBeenPaid,
                totalAffiliateHasNotBeenPaid: affiliate.totalAffiliateHasNotBeenPaid
            });
        } catch (error) {
            console.error("Error updating affiliate:", error);
        }
    }

    const toggleRevenueCatTransactionPaidStatus = async (id, isPaid, amount) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
        const transactionRef = doc(collection(doc(collection(userDocRef, 'Affiliates'), affiliateEmail), 'revenueCatTransactions'), id);

        await updateDoc(transactionRef, {
            affiliateHasBeenPaid: isPaid,
        });

        // Update `tableData` directly (if needed)
        setTableData((prevData) =>
            prevData.map((transaction) =>
                transaction.id === id ? { ...transaction, affiliateHasBeenPaid: isPaid } : transaction
            )
        );

        if (isPaid) {
            setTotalAffiliateHasBeenPaid(prevTotal => prevTotal + amount);
            setTotalAffiliateHasNotBeenPaid(prevTotal => prevTotal - amount);
        } else {
            setTotalAffiliateHasBeenPaid(prevTotal => prevTotal - amount);
            setTotalAffiliateHasNotBeenPaid(prevTotal => prevTotal + amount);
        }
    };

    const handleSelectItem = (revenueCatId) => {
        setSelectedItems(prevState => {
            const newState = new Set(prevState);
            if (newState.has(revenueCatId)) {
                newState.delete(revenueCatId);
            } else {
                newState.add(revenueCatId);
            }
            return newState;
        });
    };

    const handleSelectAll = () => {
        const allItems = new Set(tableData.map((transaction) => transaction.id));

        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.size === allItems.size) {
                return new Set(); // Clear all selections
            } else {
                return allItems; // Select all items
            }
        });
    };

    const markAllAsPaid = async () => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
    
        // Loop through all selected items
        const promises = Array.from(selectedItems).map(async (transactionId) => {
            const transactionRef = doc(
                collection(doc(collection(userDocRef, 'Affiliates'), affiliateEmail), 'revenueCatTransactions'),
                transactionId
            );
    
            const transaction = tableData.find((t) => t.id === transactionId);
            if (!transaction) return; // Skip if transaction not found in tableData
    
            const amountToAdd = (transaction.price * companyAffiliatePaymentPercentage) / 100;
    
            // Update in Firestore
            await updateDoc(transactionRef, { affiliateHasBeenPaid: true });
    
            // Update local state
            setTotalAffiliateHasBeenPaid((prev) => prev + amountToAdd);
            setTotalAffiliateHasNotBeenPaid((prev) => prev - amountToAdd);
    
            // Optimistically update the tableData
            setTableData((prevData) =>
                prevData.map((t) =>
                    t.id === transactionId ? { ...t, affiliateHasBeenPaid: true } : t
                )
            );
        });
    
        // Wait for all updates to finish
        await Promise.all(promises);
    
        // Clear selected items after marking them as paid
        setSelectedItems(new Set());
    };
    

    const filteredTableData = tableData
        // .filter((transaction) => {
        //     if (paymentFilter === 'all') return true;
        //     if (paymentFilter === 'paid') return transactionsAffiliateHasBeenPaidFor.includes(transaction.transaction_id);
        //     if (paymentFilter === 'unpaid') return !transactionsAffiliateHasBeenPaidFor.includes(transaction.transaction_id);
        //     return true; // Default case
        // })
        .map((transaction, index) => (
            <tr key={index}>
                <td className="text-center">
                    <input
                        type="checkbox"
                        checked={selectedItems.has(transaction.id)}
                        onChange={() => handleSelectItem(transaction.id)}
                    />
                </td>
                <td>{getAffiliateNameFromUrlString(transaction.app_user_id)}</td>
                <td>{transaction.transaction_id}</td>
                <td>{transaction.id}</td>
                <td>{transaction.type}</td>
                <td>{transaction.product_id}</td>
                <td>{formatDateOutput(transaction.purchased_at_ms)}</td>
                <td>{formatDateOutput(transaction.expiration_at_ms)}</td>
                <td>{transaction.price}</td>
                <td>
                    {(transaction.price * companyAffiliatePaymentPercentage) / 100}
                </td>
                <td
                    className={` ${
                        transaction.affiliateHasBeenPaid ? 'text-green-600' : 'text-red-600'
                    }`}
                >
                    {transaction.affiliateHasBeenPaid ? "Paid" : "Unpaid"}
                </td>
                <td className="whitespace-nowrap">
                    {transaction.affiliateHasBeenPaid ? (
                        <button onClick={() => toggleRevenueCatTransactionPaidStatus(transaction.id, !transaction.affiliateHasBeenPaid, transaction.price)} className="text-red-600">
                            Mark as Unpaid
                        </button>
                    ) : (
                        <button onClick={() => toggleRevenueCatTransactionPaidStatus(transaction.id, !transaction.affiliateHasBeenPaid, transaction.price)} className="text-purple-600">
                            Mark as Paid
                        </button>
                    )}
                </td>
            </tr>
        ));


    return (
        <div>
            <h2 className="mt-4 mb-4 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">
                Affiliate's Transactions
            </h2>
            <div className="flex flex-wrap items-center space-y-4 md:space-y-0 md:space-x-4">
                <div className="">
                    <select
                        id="paymentFilter"
                        value={paymentFilter}
                        onChange={(e) => setPaymentFilter(e.target.value)}
                        className="px-10 py-2 border rounded-md"
                    >
                        <option value="all">All</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                    </select>
                </div>
                {/* <div className="flex-grow">
                    <label htmlFor="startDate" className="hidden">
                        Start Date:
                    </label>
                    <DateInputWithPlaceholder
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setLocalStartDate(e.target.value)}
                        onBlur={handleStartDateBlur}
                        placeholder="Start Date"
                    />
                </div>
                <div className="flex-grow">
                    <label htmlFor="endDate" className="hidden">
                        End Date:
                    </label>
                    <DateInputWithPlaceholder
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setLocalEndDate(e.target.value)}
                        onBlur={handleEndDateBlur}
                        placeholder="End Date"
                    />
                </div> */}
            </div>
            <table className="dataTable">
                <thead>
                    <tr>
                        <th className="text-center">
                            <input
                                type="checkbox"
                                checked={selectedItems.size === tableData.length && tableData.length > 0}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th>Customer UUID</th>
                        <th>Transaction ID</th>
                        <th>RevenueCat ID</th>
                        <th>Transaction Type</th>
                        <th>Product ID</th>
                        <th>Original Purchase Date</th>
                        <th>Expiration Date</th>
                        <th>Total Earnings ($)</th>
                        <th>Amount Owed to Affiliate</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {filteredTableData}

                    <tr className="totalRow">
                    
                        <td colSpan="15">
                            <button onClick={markAllAsPaid} className="text-purple-600">
                                Mark All Paid
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr className="totalRow">
                    <td colSpan="2">Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            {/* Should be total for selected, not for all. */}
                            {/* {tableData.flatMap(row => Object.values(row.transactions))
                                .filter(purchase => purchase.amountUSD !== null && purchase.amountUSD !== undefined)
                                .reduce((total, purchase) => total + purchase.amountUSD, 0)
                                .toFixed(2)} */}
                        </td>
                        <td>
                            {/* {tableData.flatMap(row => Object.values(row.transactions))
                                .filter(purchase => purchase.amountUSD !== null && purchase.amountUSD !== undefined)
                                .reduce((total, purchase) => total + purchase.amountUSD, 0)
                                .toFixed(2)

                                * (companyAffiliatePaymentPercentage / 100)} */}
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

const DateInputWithPlaceholder = ({ id, value, onChange, onBlur, placeholder }) => {
    const [isFocused, setIsFocused] = useState(false);
  
    const handleFocus = (e) => {
      setIsFocused(true);
      e.target.type = 'date';
      e.target.placeholder = '';
    };
  
    const handleBlur = (e) => {
      if (!e.target.value) {
        e.target.type = 'text';
        e.target.placeholder = placeholder;
      }
      setIsFocused(false);
      onBlur && onBlur(e);
    };
  
    return (
      <div className="relative">
        <input
          type="text"
          id={id}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={isFocused ? '' : placeholder}
          className="block w-full appearance-none rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-500 sm:text-sm"
          style={{ width: '100%', minWidth: '180px' }}
        />
      </div>
    );
};

const formatDateOutput = (date) => {
    if (!date) {
        return "N/A";
    }
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).format(new Date(date));
};

const getAffiliateNameFromUrlString = (url) => {
    const delimiter = url.includes('-') ? '-' : '/';

    if (url.includes(delimiter)) {
        const parts = url.split(delimiter);
        return parts[parts.length - 1];
    }

    return url;
};

export default AffiliateRevenueCatSalesTransactions;
