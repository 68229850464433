import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const AffiliateEvents = ({
    tableData,
    setTableData,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    user
}) => {
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [paymentFilter, setPaymentFilter] = useState('unpaid'); // 'all', 'paid', 'unpaid'
    const [localStartDate, setLocalStartDate] = useState(startDate);
    const [localEndDate, setLocalEndDate] = useState(endDate);

    const handleStartDateBlur = () => {
        setStartDate(localStartDate);
    };

    const handleEndDateBlur = () => {
        setEndDate(localEndDate);
    };

    const handleSelectItem = (eventId) => {
        setSelectedItems(prevState => {
            const newState = new Set(prevState);
            if (newState.has(eventId)) {
                newState.delete(eventId);
            } else {
                newState.add(eventId);
            }
            return newState;
        });
    };

    const handleSelectAll = () => {
        const allItems = new Set(tableData.map(event => event.id));
        if (selectedItems.size === allItems.size) {
            setSelectedItems(new Set());
        } else {
            setSelectedItems(allItems);
        }
    };

    const markEventAsPaid = async (eventId) => {
        try {
            await updateDoc(doc(db, 'Companies', user.uid, 'Events', eventId), { status: 'paid' });
            // Update the local tableData directly to reflect the change
            const updatedTableData = tableData.map(event =>
                event.id === eventId ? { ...event, status: 'paid' } : event
            );
            setTableData(updatedTableData);
            setSelectedItems(prevState => new Set([...prevState].filter(id => id !== eventId)));
        } catch (error) {
            console.error("Error marking event as paid:", error);
        }
    };

    const markEventAsUnpaid = async (eventId) => {
        try {
            await updateDoc(doc(db, 'Companies', user.uid, 'Events', eventId), { status: 'unpaid' });
            // Update the local tableData directly to reflect the change
            const updatedTableData = tableData.map(event =>
                event.id === eventId ? { ...event, status: 'unpaid' } : event
            );
            setTableData(updatedTableData);
            setSelectedItems(prevState => new Set([...prevState].filter(id => id !== eventId)));
        } catch (error) {
            console.error("Error marking event as unpaid:", error);
        }
    };
    const filteredTableData = tableData
        .filter(event => paymentFilter === 'all' || event.status === paymentFilter)
        .map((event, index) => (
            <tr key={event.id}>
                <td className="text-center">
                    <input
                        type="checkbox"
                        checked={selectedItems.has(event.id)}
                        onChange={() => handleSelectItem(event.id)}
                    />
                </td>
                <th>{event.endUserUUID}</th>
                <td>{event.eventUUID}</td>
                <td>{event.eventName}</td>
                <td>{formatDateOutput(event.dateTime._seconds)}</td>
                <td className={event.status === 'paid' ? 'text-green-600' : 'text-red-600'}>
                    {event.status.charAt(0).toUpperCase() + event.status.slice(1)}
                </td>
                <td>
                    {event.status === 'paid' ? (
                        <button onClick={() => markEventAsUnpaid(event.id)} className="text-red-600">
                            Mark as Unpaid
                        </button>
                    ) : (
                        <button onClick={() => markEventAsPaid(event.id)} className="text-purple-600">
                            Mark as Paid
                        </button>
                    )}
                </td>
            </tr>
        ));

    return (
        <div>
            <h2 className="mt-4 mb-4 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">
                Affiliate's Events
            </h2>
            <div className="flex flex-wrap items-center space-y-4 md:space-y-0 md:space-x-4">
                <div>
                    <select
                        id="paymentFilter"
                        value={paymentFilter}
                        onChange={(e) => setPaymentFilter(e.target.value)}
                        className="px-10 py-2 border rounded-md"
                    >
                        <option value="all">All</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                    </select>
                </div>
                <div className="flex-grow">
                    <DateInputWithPlaceholder
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setLocalStartDate(e.target.value)}
                        onBlur={handleStartDateBlur}
                        placeholder="Start Date"
                    />
                </div>
                <div className="flex-grow">
                    <DateInputWithPlaceholder
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setLocalEndDate(e.target.value)}
                        onBlur={handleEndDateBlur}
                        placeholder="End Date"
                    />
                </div>
            </div>
            <table className="dataTable">
                <thead>
                    <tr>
                        <th className="text-center">
                            <input
                                type="checkbox"
                                checked={selectedItems.size === tableData.length}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th>End User UUID</th>
                        <th>Event UUID</th>
                        <th>Event Name</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTableData}
                </tbody>
            </table>
        </div>
    );
};

// Helper component for date input
const DateInputWithPlaceholder = ({ id, value, onChange, onBlur, placeholder }) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e) => {
        setIsFocused(true);
        e.target.type = 'date';
    };

    const handleBlur = (e) => {
        if (!e.target.value) {
            e.target.type = 'text';
        }
        setIsFocused(false);
        onBlur && onBlur(e);
    };

    return (
        <div className="relative">
            <input
                type="text"
                id={id}
                value={value}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={placeholder}
                className="block w-full appearance-none rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-500 sm:text-sm"
                style={{ width: '100%', minWidth: '180px' }}
            />
        </div>
    );
};

// Helper to format timestamp from seconds
const formatDateOutput = (seconds) => {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString('en-GB') + ' ' + date.toLocaleTimeString('en-GB');
};

export default AffiliateEvents;
